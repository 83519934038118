import Vue from 'vue'
import Vuex from 'vuex'

// Persisted Data Plugin
import createPersistedState from 'vuex-persistedstate'
import secureStorage from './secure-storage'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import userAuthStoreModule from './user-auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'user-auth': userAuthStoreModule,
  },
  strict: process.env.DEV,
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => secureStorage.getItem(key),
        setItem: (key, value) => secureStorage.setItem(key, value),
        removeItem: key => secureStorage.removeItem(key),
      },
    }),
  ],
})
