import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/activities',
      name: 'activities',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Activities',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/interactive-reporter/',
      name: 'interactive-reporter',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Interactive Reporter',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Interactive Reporter',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/daily-appointment/',
      name: 'daily-appointment',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Daily Appointment',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Daily Appointment',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/daily-visit/',
      name: 'daily-visit',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Daily Visit',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Daily Visit',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/occurred-visits/',
      name: 'occurred-visits',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Occurred Visits',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Occurred Visits',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/canceled-visits/',
      name: 'canceled-visits',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Canceled Visits',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'Canceled Visits',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/reports/general/',
      name: 'general',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'General',
        breadcrumb: [
          {
            text: 'Reports',
          },
          {
            text: 'General',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: '/leads',
      name: 'leads',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Leads',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/appointments',
      name: 'appointments',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Appointments',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/crm',
      name: 'crm',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'CRM',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/lists/roaster-importer/',
      name: 'roaster-importer',
      component: () => import('@/views/lists/roasterImporter/RoasterImporter.vue'),
      meta: {
        pageTitle: 'Roaster Importer',
        breadcrumb: [
          {
            text: 'Lists',
          },
          {
            text: 'Roaster Importer',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/lists/roaster-configuration/:id',
      name: 'roaster-configuration',
      component: () => import('@/views/lists/roasterConfiguration/RoasterConfiguration.vue'),
      meta: {
        pageTitle: 'Roaster Configuration',
        breadcrumb: [
          {
            text: 'Lists',
          },
          {
            text: 'Roaster Importer',
          },
          {
            text: 'Roaster Configuration',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/profile/kardex-profile/',
      name: 'kardex-profile',
      component: () => import('@/views/profile/kardexprofile/KardexProfile.vue'),
      meta: {
        pageTitle: 'Kardex Profile',
        breadcrumb: [
          {
            text: 'profile',
          },
          {
            text: 'Kardex Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/system/ComingSoon.vue'),
      meta: {
        pageTitle: 'Dashboard',
      },
    },
    {
      path: '/two-factor-auth',
      name: 'two-factor-auth',
      component: () => import('@/views/TwoFactorAuthentication.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/users-logged',
      name: 'users-logged',
      component: () => import('@/views/UsersLogged.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/system/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const isLoggedIn = store.getters['user-auth/isLoggedIn']
    if (isLoggedIn) next()
    else next('/')
  } else {
    next()
  }
})

export default router
